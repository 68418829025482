<template>
  <select class="form-control mr-3" v-model="settings.lang">
    <option v-for="(lang, i) in $ml.list" :key="i">{{ lang }}</option>
  </select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "lv-menu",

  watch: {
    thislang(lang) {
      this.$ml.change(lang);
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
};
</script>
